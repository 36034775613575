import axios from 'axios'

// initial state
const state = {
  endpoint: "/api/v1/guest_speakers",
  all: [],
  initialState: [],
  newGuestSpeaker: {
    id: null,
    attributes: {
      user_attributes: {
        id: '',
        firstname: '',
        lastname: '',
        email: ''
      },
      phone: '',
      user_id: '',
      errors: [],
      user: {
        attributes: {}
      }
    }
  }
}

// getters
const getters = {
}

// actions
const actions = {
  getAllGuestSpeakers ({ commit }, params) {
    axios.get(state.endpoint, { params }).then(({ data }) => commit('setGuestSpeakers', data.data))
  },

  resetStateAll (context) {
    context.commit('resetStateAll')
  },

  createGuestSpeaker ({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.post(state.endpoint, {
        guestSpeaker: params.guestSpeaker.attributes
      }).then(({ data }) => {
        let guestSpeaker = data.data
        commit('insertGuestSpeaker', guestSpeaker)
        resolve(guestSpeaker)
      }).catch(({ response }) => {
        let guestSpeaker = response.data.data
        reject(guestSpeaker)
      })
    })
  }
}

// mutations
const mutations = {
  setGuestSpeakers (state, guestSpeakers) {
    state.all = guestSpeakers
  },

  resetStateAll (state) {
    state.all = []
  },

  insertGuestSpeaker (state, newGuestSpeaker) {
    let index = state.all.findIndex((guestSpeaker) => {
      return guestSpeaker.id === newGuestSpeaker.id
    })

    if (index === -1) {
      state.all.push(newGuestSpeaker)
    } else {
      state.all.splice(index, 1, newGuestSpeaker)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
